import(/* webpackMode: "eager", webpackExports: ["AppContextProviders"] */ "/vercel/path0/components/AppContextProviders/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CookiesPopup/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Footer/Footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Banner/Banner.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Button/Button.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Search/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/WidgetTemplate/WidgetTemplate.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/SearchNav/SearchNav.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/WidgetTrends/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
